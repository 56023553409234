.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  position: relative;
  z-index: 99;
  &-left {
    display: flex;
    align-items: center;
    gap: 50px;
  }
  &-logo {
    width: 200px;
    @media screen and (max-width: 1250px) {
      width: 150px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
